<template>
    <h4 :class="cn('font-medium text-lg leading-none m-0 mb-1 pl-8', props.class)">
        <slot />
    </h4>
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const props = defineProps<{
    class?: HTMLAttributes["class"];
}>();
</script>
