<template>
    <ComboboxGroup v-bind="delegatedProps" :class="cn('overflow-hidden p-1 text-gray-950', props.class)">
        <ComboboxLabel v-if="heading" class="px-2 py-1.5 text-xs font-medium text-gray-500">
            {{ heading }}
        </ComboboxLabel>
        <slot />
    </ComboboxGroup>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import type { ComboboxGroupProps } from "radix-vue";
import { ComboboxGroup, ComboboxLabel } from "radix-vue";

const props = defineProps<
    ComboboxGroupProps & {
        class?: HTMLAttributes["class"];
        heading?: string;
    }
>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});
</script>
